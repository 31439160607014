.grid-row {
  display: -webkit-box;
  display: -o-flex;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }
